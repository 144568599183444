<template>
  <div class="border mb-4">
    <div class="label p-4 pb-0">{{ title }}</div>
    <div @click="openFilesBrowser">
      <div class="px-4">
        <div class="-mx-1 flex flex-wrap">
          <div
            class="px-1 mb-1 w-1/6"
            v-for="(image, index) in images"
            :key="index"
          >
            <b-image
              class="border"
              :src="image ? image.thumb : $default_image_thumb"
              alt="featured-image"
              ratio="1by1"
              lazy
            ></b-image>
          </div>
          <div class="px-1 mb-1 w-1/6">
            <b-image
              class="border"
              :src="$default_add_thumb"
              alt="featured-image"
              ratio="1by1"
              lazy
            ></b-image>
          </div>
        </div>
      </div>
    </div>
    <div class="p-4">
      <b-button size="is-small" @click="openFilesBrowser">Change</b-button>
      <b-button size="is-small" class="ml-2" @click="onRemove">Remove</b-button>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { Events } from "@/events.js";
export default {
  props: {
    title: {
      type: String
    },
    image_ids: {
      type: Array
    },
    type: {
      type: String
    }
  },
  data: () => ({
    images: [],
    emit: "gallery-selected"
  }),
  watch: {
    image_ids() {
      if (this.image_ids.length) {
        store.dispatch("getFiles", { ids: this.image_ids }).then(res => {
          this.images = res.data.data.splice(0, this.image_ids.length);
        });
      } else {
        this.images = [];
      }
    }
  },
  methods: {
    openFilesBrowser() {
      this.$fb.show({
        multiple: true,
        image_ids: this.image_ids,
        emit: this.emit,
        type: this.type
      });
    },
    onRemove() {
      Events.$emit(this.emit, []);
    }
  }
};
</script>
