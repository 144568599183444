<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12 flex flex-col is-relative">
      <b-loading
        :is-full-page="false"
        v-model="$store.getters.isProcessing"
      ></b-loading>
      <div class="flex items-center mb-4">
        <b-button
          class="mr-4"
          type="is-light"
          tag="router-link"
          :to="{ name: 'Joborders' }"
          >Back</b-button
        >
        <div class="is-size-4">{{ $route.name }}</div>
      </div>
      <div class="px-4 flex-grow flex">
        <div class="-mx-6 flex justify-center flex-wrap flex-grow">
          <div class="px-2 w-8/12">
            <div class="p-4 border">
              <b-field label="ID">
                <b-input
                  v-model="code"
                  disabled
                  placeholder="AUTOGENERATED"
                ></b-input>
              </b-field>
              <b-field label="Client">
                <b-input v-model="client_name" disabled></b-input>
              </b-field>
              <b-field label="Project Name">
                <b-input v-model="project_name"></b-input>
              </b-field>
              <b-field label="ETD">
                <b-datepicker
                  :date-formatter="toYmd"
                  v-model="etd"
                  placeholder="Click to select..."
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
              <b-field label="ETA">
                <b-datepicker
                  :date-formatter="toYmd"
                  v-model="eta"
                  placeholder="Click to select..."
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
              <b-field label="Products">
                <div class="px-4">
                  <div
                    class="mb-4 flex flex-wrap border rounded -mx-4"
                    v-for="(product, index) in selectedProducts"
                    :key="index"
                  >
                    <div class="p-2 pl-4 w-1/2">
                      <div class="flex mb-2">
                        <img
                          width="50"
                          :src="
                            product.image
                              ? product.image.thumb
                              : $default_image_thumb
                          "
                        />
                        <div class="ml-4">
                          {{ product.name }}
                          <br />
                          <small>
                            {{ product.code }} |
                            {{ product.pivot.quantity }} pcs.
                          </small>
                        </div>
                      </div>
                      <div class="">
                        <b-input
                          v-model="product.pivot.remarks"
                          type="textarea"
                          rows="5"
                          size="is-small"
                        ></b-input>
                      </div>
                    </div>
                    <div class="p-2 pr-4 w-1/2">
                      <div class="h-56 bg-gray-100 overflow-y-auto p-2">
                        <div v-for="(item, index) in items" :key="index">
                          <b-dropdown
                            v-if="item.product_id == product.id"
                            class="mb-2"
                            v-model="item.stage_id"
                            aria-role="list"
                            expanded
                          >
                            <button
                              class="button is-white is-fullwidth"
                              type="button"
                              slot="trigger"
                            >
                              <template>
                                <span class="mr-4">{{ item.code }}</span>
                                <span>{{
                                  stages[item.stage_id - 1].name
                                }}</span>
                              </template>
                              <b-icon icon="menu-down"></b-icon>
                            </button>

                            <b-dropdown-item
                              v-for="stage in stages"
                              :key="stage.id"
                              :value="stage.id"
                            >
                              <div class="media">
                                <div class="media-content">
                                  <h3>{{ stage.name }}</h3>
                                </div>
                              </div>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-field>
              <!-- <b-field label="Items">
                <div class="h-56 bg-gray-100 overflow-y-auto p-2">
                        <div
                          v-for="(item, index) in items"
                          :key="index"
                        >
                          <b-dropdown
                            class="mb-2"
                            v-model="item.stage_id"
                            aria-role="list"
                            expanded
                          >
                            <button
                              class="button is-white is-fullwidth"
                              type="button"
                              slot="trigger"
                            >
                              <template>
                                <img
                        width="50"
                        :src="
                          item.product.image
                            ? item.product.image.thumb
                            : $default_image_thumb
                        "
                      />
                                <span class="mr-4"
                                  >{{
                                    item.code
                                  }}</span
                                >
                                <span>{{stages[item.stage_id - 1].name}}</span>
                              </template>
                              <b-icon icon="menu-down"></b-icon>
                            </button>

                            <b-dropdown-item
                              v-for="stage in stages"
                              :key="stage.id"
                              :value="stage.id"
                            >
                              <div class="media">
                                <div class="media-content">
                                  <h3>{{ stage.name }}</h3>
                                </div>
                              </div>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
              </b-field> -->
            </div>
          </div>
          <div class="px-2 w-4/12">
            <FeaturedGallery
              title="References"
              :image_ids="image_ids"
              type="*"
            />

            <div class="p-4 border">
              <div class="flex items-center justify-end">
                <b-button
                  class="mr-4"
                  v-if="$route.params.id"
                  type="is-danger is-light"
                  expanded
                  :disabled="$store.getters.isProcessing"
                  @click="onDelete()"
                  >Delete</b-button
                >
                <b-button
                  type="is-success"
                  expanded
                  :disabled="$store.getters.isProcessing"
                  @click="onSave"
                  >Save changes</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import FeaturedGallery from "@/components/admin/FeaturedGallery";
import store from "@/store";
import { Events } from "@/events.js";
export default {
  components: {
    Sidebar,
    FeaturedGallery
  },
  data: () => ({
    code: "",
    order_id: null,
    image_ids: [],
    products: [],
    selectedProducts: [],
    stages: [],
    items: [],
    client_name: "",
    project_name: "",
    etd: null,
    eta: null
  }),
  methods: {
    toYmd(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    onSave() {
      store.dispatch("saveJobordersForm", {
        id: this.$route.params.id || null,
        order_id: this.order_id,
        project_name: this.project_name,
        etd: this.etd,
        eta: this.eta,
        products: _.map(this.selectedProducts, product => {
          return { id: product.id, remarks: product.pivot.remarks };
        }),
        items: this.items,
        attachments: this.image_ids
      });
    },
    onDelete() {
      store.dispatch("deleteSelection", {
        message: `Do you really want to delete this order?`,
        ids: [this.$route.params.id],
        path: "joborders",
        push: "Joborders"
      });
    },
    setGalleryImageIds(ids) {
      this.image_ids = ids;
    }
  },
  beforeMount() {
    Events.$on("gallery-selected", this.setGalleryImageIds);
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store
        .dispatch(to.meta.dispatch, { id: to.params.id, fr: to.query.id })
        .then(res => {
          next(vm => {
            vm.stages = res.data.stages;
            if (res.data.order) {
              vm.order_id = res.data.order.id;
              vm.client_name = res.data.order.user.name;
              vm.code = res.data.order.code;
              vm.items = res.data.order.items;
              _.forEach(res.data.order.products, product => {
                vm.selectedProducts.push(product);
              });
            }
            if (res.data.joborder) {
              vm.order_id = res.data.joborder.order.id;
              vm.client_name = res.data.joborder.order.user.name;
              vm.project_name = res.data.joborder.project_name;
              vm.etd = new Date(res.data.joborder.etd);
              vm.eta = new Date(res.data.joborder.eta);
              vm.code = res.data.joborder.code;
              vm.items = res.data.joborder.order.items;
              _.forEach(res.data.joborder.files, file => {
                vm.image_ids.push(file.id);
              });
              _.forEach(res.data.joborder.order.products, product => {
                vm.selectedProducts.push(product);
              });
            }
          });
        })
        .catch(() => {
          next({ name: "Joborders" });
        });
    }
  }
};
</script>
